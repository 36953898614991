import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/developers/meta.png'
import tag from '../assets/images/tags/WNIS-1.svg'
import Svyat from '../assets/images/story/wolt/svyat.png'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import ColumnsBlock, { gridSpacing } from '../components/blocks/ColumnsBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import ShadowFrame from '../components/primitives/ShadowFrame'
import Stack from '../components/primitives/Stack'
import Blurb from '../components/system/Blurb'
import { responsiveScale } from '../styles/helpers'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'

// Import the RegistrationFormSection component (adjust the path as needed)
import { RegistrationFormSection } from '../components/form/WhatsNewInSwarmia'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/developers/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(relativePath: { eq: "images/developers/pr-insights.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1322)
      }
    }
    lead2: file(
      relativePath: { eq: "images/product/work-log/anti-patterns.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1440)
      }
    }
    metrics: file(relativePath: { eq: "images/developers/metrics.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blockers: file(
      relativePath: { eq: "images/product/overview/blockers.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    career: file(
      relativePath: { eq: "images/developers/career-discussions.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    agreements: file(
      relativePath: { eq: "images/developers/working-agreements.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    comments: file(
      relativePath: { eq: "images/developers/slack-comments.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    recaps: file(relativePath: { eq: "images/developers/daily-recaps.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    completed: file(
      relativePath: { eq: "images/developers/issue-completed.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const DevelopersPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Swarmia for developers: A linter for teamwork"
      variant="dark"
      isNew
      description="Just like a linter helps improve your code, Swarmia helps improve the way your software development team works together."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>What’s new in Swarmia</>}
        content="Just like a linter helps improve your code, Swarmia helps improve the way your team collaborates and communicates."
        backgroundImage={getImage(data.backgroundImage)!}
        primaryButton={{
          text: 'Register',
          href: '#registration', // Updated to scroll to the registration form
        }}
        secondaryButton={null}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      {/* Registration Form Section with better paddings */}
      <Block paddingY={responsiveScale(64)} id="registration">
        <RegistrationFormSection />
      </Block>
      <CTABlock
        title="Join the high-performing teams using Swarmia today."
        primaryButton={{
          text: 'Start free trial',
          href: 'https://app.swarmia.com/signup/',
        }}
        secondaryButton={null}
      />
    </Layout>
  )
}

export default DevelopersPage

const AlexQuote = () => {
  return (
    <ShadowFrame
      paddingTop={40}
      paddingRight={40}
      paddingBottom={32}
      paddingLeft={40}
    >
      <Stack space={20}>
        <Box
          fontFamily="FactorA"
          fontSize={24}
          lineHeight="150%"
          fontWeight={700}
          maxWidth={500}
        >
          “Swarmia’s Slack notifications make it easy to keep track of open pull
          requests across all our repos. They help me quickly find the
          conversations that need my input.”
        </Box>
        <Row space={16} alignItems="center">
          <Box.img loading="lazy" src={Svyat} width={64} borderRadius={9999} />
          <Stack>
            <Box font="small" fontWeight={700}>
              Svyat Sobol
            </Box>
            <Box font="small">Lead Engineer at Wolt</Box>
          </Stack>
        </Row>
      </Stack>
    </ShadowFrame>
  )
}
